import { PairResponse } from "../models/receipt/PairResponse";
import { PairRequest } from "../models/receipt/PairRequest";
import JSONbig from 'json-bigint'

const RECEIPT_API_URL = "https://receipts-sandbox.fiskaltrust.cloud";

export default class ReceiptService {
    static PairAsync = async (pin: string): Promise<PairResponse> => {
        try {
            const request: PairRequest = {
                Pin: pin
            };
            const response = await fetch(`${RECEIPT_API_URL}/v1/pair`, {
                method: 'POST',
                body: JSONbig.stringify(request),
                headers: { "Content-type": "application/json" },
            });

            return JSONbig.parse(await response.text());
        } catch (error) {
            alert("Error while tryng to pair. Regenerate the pin in fiskaltrust portal and try to pair again.");
            throw error;
        }
    }
}