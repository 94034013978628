import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const P = styled.p`
text-align: right;
margin-right: 10px;
font-size: 22px;
height: 2vh;
`;

const Clock: React.FC = () => {
    const [currentTime, setCurrentTime] = useState<string>(new Date().toLocaleTimeString());
    const [currentDate, setCurrentDate] = useState<string>(new Date().toLocaleDateString());

    useEffect(()=>{
        var timer = setInterval(() => {
            setCurrentTime(new Date().toLocaleTimeString());
            setCurrentDate(new Date().toLocaleDateString());
        }, 500);
        return ()=>{
            clearInterval(timer);
        }
    },[currentTime,currentDate])
    return (
        <P>
            {currentDate}
            &nbsp;&nbsp;&nbsp;&nbsp;
            {currentTime}
        </P>
    );
};

export default Clock;