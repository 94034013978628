import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

type Props = {
    isModalOpen: boolean;
    onCloseModal: () => void;
}

const MailModal: React.FC<Props> = (props) => {
    const [email, setEmail] = useState<string>();

    useEffect(() => {
        setEmail("");
    }, [props.isModalOpen])

    return (
        <Modal show={props.isModalOpen} centered>
            <Modal.Header>
                <Modal.Title>Send per Email</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Label htmlFor="email">Please enter your e-mail address:</Form.Label>
                <Form.Control
                    type="text"
                    value={email}
                    onChange={(event) => setEmail(event.currentTarget.value)}
                    id="email"
                    aria-describedby="email"
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => props.onCloseModal()}> Cancel</Button>
                <Button variant="primary" className='btn-primary' onClick={async () => {

                    props.onCloseModal();
                }}> Send</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default MailModal;