import { useEffect, useState } from 'react';
import './App.css';
import Home from './components/home/Home';
import { MqttClient } from "mqtt";
import { SubmittedPrintMessage } from './models/receipt/SubmittedPrintMessage';
import PrintReceipt from './components/receipt/PrintReceipt';
import { Button, Form, Modal } from 'react-bootstrap';
import MqttHelper from './helpers/MqttHelper';
import { BusMessages } from './constants/BusMessages';
import './styles/ButtonStyles.css';

function App() {
  const [cashboxId, setCashboxId] = useState<string>("");
  const [showReceipt, setShowReceipt] = useState<boolean>(false);
  const [submitedPrintMessage, setSubmitedPrintMessage] = useState<SubmittedPrintMessage>();
  const [isConnectedModalOpen, setIsConnectedModalOpen] = useState<boolean>(false);
  let client: MqttClient;

  useEffect(() => {
    let cashboxId = localStorage.getItem("cashboxId");
    let accessToken = localStorage.getItem("accessToken");
    if (cashboxId && accessToken) {
      onCashboxChange(cashboxId, accessToken);
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <div className="App">
      {
        showReceipt ? <PrintReceipt onCloseReceipt={onCloseReceipt} submitedPrintMessage={submitedPrintMessage} /> : <Home onCashboxChange={onCashboxChange} />
      }
      <Modal show={isConnectedModalOpen} centered>
        <Modal.Header>
          <Modal.Title>Connected</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Connected to global bus (CashBoxID: {cashboxId})</Form.Label>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className='btn-primary' onClick={() => setIsConnectedModalOpen(false)}> OK</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );

  function onCloseReceipt() {
    setShowReceipt(false);
  }

  function onCashboxChange(newCashboxId: string, newAccessToken: string) {
    MqttHelper.ConnectAndSubscribe(client, cashboxId, newCashboxId, onConnected, onMessageReceived);
    localStorage.setItem("cashboxId", newCashboxId);
    localStorage.setItem("accessToken", newAccessToken);
    setCashboxId(newCashboxId);
  }

  function onConnected() {
    setIsConnectedModalOpen(true);
  }

  function onMessageReceived(topic: string, message: string) {
    if (topic.includes(BusMessages.SubmittedForPrinting)) {
      setSubmitedPrintMessage(JSON.parse(message));
      setShowReceipt(true);
    }
  }
}

export default App;
