import React, { useEffect, useState } from 'react';
import { useLongPress } from 'use-long-press';
import { Button, Form, Modal } from 'react-bootstrap';
import ReceiptService from '../../services/ReceiptService';
import Footer from '../common/Footer';
import styled from 'styled-components';
import Clock from '../common/Clock';

type Props = {
    onCashboxChange: (cashboxId: string, accessToken: string) => void;
}

const StrechedButton = styled.button`
    width: 100%;
    height: 90vh;
    background: transparent;
    color: gray;
    border: 0px solid transparent;
`;

const Hr = styled.hr`
border-top: 2px solid black;
margin-top: 0px;
margin-bottom: 0px;
`;

const Home: React.FC<Props> = (props) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [pin, setPin] = useState<string>("");

    const bindLongPress = useLongPress(() => {
        setIsModalOpen(true);
    });

    useEffect(() => {
        let cashboxId = localStorage.getItem("cashboxId");
        if (!cashboxId) {
            setIsModalOpen(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div style={{ paddingTop: "1vh" }}>
            <Clock />
            <Hr />
            <StrechedButton {...bindLongPress()}>
                <p className='text-center'>Waiting for receipts to show...</p>
                <Footer />
            </StrechedButton>

            <Modal show={isModalOpen} centered>
                <Modal.Header>
                    <Modal.Title style={{userSelect: "none"}}>Pair device?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Label htmlFor="inputPin" style={{userSelect: "none"}}>Currently not paired. Enter a pairing pin</Form.Label>
                    <Form.Control
                        type="number"
                        value={pin}
                        autoFocus
                        onChange={(event) => setPin(event.currentTarget.value)}
                        id="inputPin"
                        aria-describedby="passwordHelpBlock"
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setIsModalOpen(false)}> Cancel</Button>
                    <Button variant="primary" onClick={async () => {
                        let response = await ReceiptService.PairAsync(pin);
                        props.onCashboxChange(response.cashBoxId, response.accessToken);
                        setIsModalOpen(false);
                    }}> Pair</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Home;