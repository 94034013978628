import React, { useEffect, useState } from 'react';
import { SubmittedPrintMessage } from '../../models/receipt/SubmittedPrintMessage';
import QRCode from 'react-qr-code';
import { Button, Col, Row } from 'react-bootstrap';
import Footer from '../common/Footer';
import styled from 'styled-components';
import MailModal from '../common/MailModal';

const ReceiptTimeP = styled.p`
text-align: left;
margin: 0px;
`

const ReceiptAmountP = styled.p`
text-align: right;
font-size: x-large;
`

type Props = {
    submitedPrintMessage?: SubmittedPrintMessage;
    onCloseReceipt: () => void;
}

const receiptUrl = "https://receipts-sandbox.fiskaltrust.cloud";

const PrintReceipt: React.FC<Props> = (props) => {
    const receiptAmount = props.submitedPrintMessage?.printrequest.response.cbReceiptAmount 
    ? `${props.submitedPrintMessage?.printrequest.response.cbReceiptAmount?.toFixed(2)}€`
    : `${props.submitedPrintMessage?.printrequest.request.cbChargeItems.reduce((a,b) => a = a + b.Amount , 0).toFixed(2)}€`;
    const receiptDate = props.submitedPrintMessage 
    ? new Date(props.submitedPrintMessage.printrequest.response.ftReceiptMoment)
    : null;
    const [countdown, setCountDown] = useState<number>(30);
    const [isSendEmailVisible, setIsSendEmailVisible] = useState<boolean>(false);
    const height = document.documentElement.clientHeight * 0.65;
    const qrSize = height * 0.5;

    useEffect(() => {
        const interval = setInterval(()=>{
            if(countdown < 1){
                clearInterval(interval);
                setCountDown(30);
                props.onCloseReceipt();
            }
            else{
                setCountDown(countdown-1);
            }
        }, 1000);
        return ()=>{
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [props.submitedPrintMessage?.printrequest.response.ftQueueItemID, countdown]);

      useEffect(()=>{
        setCountDown(30);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },[props.submitedPrintMessage?.printrequest.response.ftQueueItemID])

    
    return (
        <div style={{padding: "5px"}}>
            <Row style={{ height: "5vh" }}>
                <Col xs={7}>
                    <ReceiptTimeP>{props.submitedPrintMessage?.printrequest.response.ftReceiptIdentification}</ReceiptTimeP>
                    <ReceiptTimeP>{receiptDate?.toLocaleDateString()}&nbsp;{receiptDate?.toLocaleTimeString()}</ReceiptTimeP>
                </Col>
                <Col xs={5}>
                    <ReceiptAmountP>{receiptAmount}</ReceiptAmountP>
                </Col>
            </Row>
            <hr />
            <QRCode
                value={`${receiptUrl}/${props.submitedPrintMessage?.printrequest.response.ftQueueID}/${props.submitedPrintMessage?.printrequest.response.ftQueueItemID}`}
                size={qrSize}
                style={{marginTop: "10vh"}} />
            <div className="d-grid gap-2" style={{marginTop: "5vh"}}>
                <Button variant="primary" size='lg' className='btn-primary' onClick={props.onCloseReceipt}>OK</Button>
                <Button variant="primary" size='lg' className='btn-primary'>Print [{countdown}]</Button>
                <Button variant="primary" size='lg' className='btn-primary' onClick={()=>setIsSendEmailVisible(true)}>Send per Mail</Button>
            </div>
            <Footer />
            <MailModal isModalOpen={isSendEmailVisible} onCloseModal={()=>setIsSendEmailVisible(false)} />
        </div>
    );
};

export default PrintReceipt;