import React from 'react';
import { Col, Row, Image } from 'react-bootstrap';
import FiskaltrustLogo from '../../resources/images/fiskaltrust_logo.svg'
import styled from 'styled-components';

const StyledFooter = styled.footer`
    position: absolute;
    bottom: 5px;
    left: 0px;
    right: 0px;
`;
const Footer: React.FC = () => (
    <StyledFooter>
        <Row className="justify-content-md-center">
            <Col md={12}>
                <Image height={50} src={FiskaltrustLogo}></Image>
            </Col>
            <Col md={12}>
                <p>Version 1.0.0 DEMO</p>
            </Col>
        </Row>
    </StyledFooter>
);

export default Footer;